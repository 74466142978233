import Layout from '@/components/Layout'
import { Link } from 'gatsby'
import React, { Component, MouseEvent } from 'react'
import { FaImage, FaTimes, FaVideo } from 'react-icons/fa'

export default class EstatePage extends Component<{}, { active: number, activeImage: { type: string, src: string }, showForm: boolean }> {
    // @ts-ignore
    data = this.props.pageContext as {
        town: string
        about: { about: string }
        image: { file: { url: string } }
        images: [{ file: { url: string, contentType: string } }] | []
        googleMapsUrl: { googleMapsUrl: string }
    }

    constructor(props) {
        super(props);

        if (!this.data.images) this.data.images = [];

        this.PageSelector = this.PageSelector.bind(this);
        this.ViewImage = this.ViewImage.bind(this);

        this.state = {
            active: Number(typeof window !== "undefined" ? window.location.hash.substr(1) : 0) || 0,
            activeImage: null,
            showForm: false
        }

    }

    PageSelector(e: MouseEvent<HTMLUListElement>) {
        const active = Number((e.target as HTMLElement).getAttribute("data-id"));

        if (typeof window !== "undefined") {
            window.scrollTo(null, 0);
            window.location.hash = active.toString();
        }

        this.setState({
            active
        });
    }

    ViewImage(e: MouseEvent<HTMLDivElement>) {
        this.setState({
            activeImage: { type: e.currentTarget.classList.contains("video") ? "video" : "image", src: e.currentTarget.getAttribute("data-image") }
        });
    }

    componentDidUpdate() {
        document.body.style.overflow = this.state.activeImage || this.state.showForm ? "hidden" : ""
        const marker = document.querySelector('.leaflet-marker-icon');
        if (marker) marker.setAttribute("src", marker.getAttribute("src").replace("\")marker-icon.png", ""));
    }

    render() {
        return (
            <Layout title={this.data.town} active={2}>
                {typeof window !== "undefined" ? (
                    <>
                        <button onClick={() => this.setState({ showForm: !this.state.showForm })} className="interest">
                            Intresseanmälan
                        </button>

                        <div onClick={() => this.setState({ activeImage: null })} style={{ display: this.state.activeImage ? "block" : "none" }} className="image-preview overlay">
                            <FaTimes />
                            {(this.state.activeImage && this.state.activeImage.type === "image") ? (
                                <img src={this.state.activeImage.src} />
                            ) : (
                                <video playsInline controls autoPlay src={this.state.activeImage ? this.state.activeImage.src : ""}></video>
                            )}
                        </div>

                        <div className="form overlay" style={{ display: this.state.showForm ? "block" : "none" }}>
                            <FaTimes onClick={() => this.setState({ showForm: false })} />
                            <div className="form__inner">
                                <p style={{ marginBottom: "0.5rem" }}><Link to="/inlägg/2022-09-04/kapabos-integritetspolicy">Så hanterar vi dina personuppgifter</Link></p>
                                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdzoA_gPqFWwlGTk_4y3-zWxKOTiL3UV6g-wI69OAHMnQs0dg/viewform?embedded=true" width="700" height="520" frameBorder="0" marginHeight={0} marginWidth={0}>Läser in …</iframe>
                            </div>
                        </div>

                        <section className="ep-hero">
                            <div className="ep-hero__wallpaper" style={{ backgroundImage: `url(${this.data.image.file.url})`, backgroundSize: "cover" }}></div>
                            <div className="p-margin">
                                <div className="split-50">
                                    <div>
                                        <h1 className="v-center">{this.data.town}</h1>
                                    </div>
                                    <div id="imageHolder">
                                        {/*}
                                        <div className="thumbnail" style={{ backgroundImage: `url(${this.data.image.file.url})`, backgroundSize: "cover" }}></div>
                                        {*/}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="ep-info-section">
                            <div className="ep-info-section__menu">
                                <ul onClick={this.PageSelector}>
                                    <li data-id="0" className={this.state.active == 0 ? "active" : ""}>Om</li>
                                    <li data-id="1" className={this.state.active == 1 ? "active" : ""}>Bilder</li>
                                    <li data-id="2" className={this.state.active == 2 ? "active" : ""}>Karta</li>
                                </ul>
                            </div>
                            <div className="ep-content">
                                {(this.state.active == 0) ? (
                                    <div className="fadeIn p-margin">
                                        <p>
                                            {this.data.about.about}
                                        </p>
                                    </div>
                                ) : ""}
                                {(this.state.active == 1) ? (
                                    <div className="fadeIn p-margin">
                                        <div className="image-grid">
                                            {(this.data.images.length == 0) ? (
                                                <div>
                                                    <h1>Inga bilder hittades.</h1>
                                                </div>
                                            ) : this.data.images.map(i => {
                                                console.log(i);
                                                if (i.file.contentType === "video/mp4") {
                                                    return (<div key={i.file.url} onClick={this.ViewImage} data-image={i.file.url} className="image-grid__image video">
                                                        <FaVideo />
                                                        <video playsInline onMouseEnter={e => e.currentTarget.play()} onMouseLeave={e => { e.currentTarget.pause(); e.currentTarget.currentTime = 0; }} muted={true} src={i.file.url}></video>
                                                    </div>)
                                                } else {
                                                    return (<div key={i.file.url} onClick={this.ViewImage} data-image={i.file.url} style={{ backgroundImage: `url(${i.file.url})` }} className="image-grid__image">
                                                        <FaImage />
                                                    </div>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                ) : ""}
                                {(this.state.active == 2) ? (
                                    <div className="map fadeIn">
                                        <iframe src={this.data.googleMapsUrl.googleMapsUrl} width="600" height="450" allowFullScreen={false} loading="lazy"></iframe>
                                    </div>
                                ) : ""}
                            </div>
                        </section>
                    </>
                ) : ""}
            </Layout>
        )
    }
}
